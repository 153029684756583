import { useEffect, useState } from "react";
import { Countdown } from "react-daisyui";
import toast, { Toaster } from "react-hot-toast";
import { TbHexagons } from "react-icons/tb";

function App(): JSX.Element {
  const [counter, setCounter] = useState(90);
  const redirectPath =
    "https://timehive.spearhead-software.com?tenantId=fba1295b-5df5-4fce-8362-8b195f062676";

  useEffect(() => {
    const counterInterval = setInterval(() => {
      if (counter === 0) {
        clearInterval(counterInterval);
        window.location.replace(redirectPath);
      } else {
        setCounter((c) => c - 1);
      }
    }, 1000);

    return () => {
      clearInterval(counterInterval);
    };
  }, [counter, redirectPath]);

  return (
    <div className='hero-content flex-col m-auto'>
      <div role='alert' className='alert alert-warning'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='stroke-current shrink-0 h-6 w-6'
          fill='none'
          viewBox='0 0 24 24'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
          />
        </svg>
        <span>
          This site will cease to be operational on <strong>February 1st, 2024</strong>
        </span>
      </div>
      <p>Hey there, we are moving you over to our new experience:</p>
      <h1 className='text-5xl font-bold'>TimeHive</h1>
      <strong>
        You may have to sign up again, but all of your previous submissions have been saved
      </strong>
      <p className='text-center'>
        You will be redirected to the new site momentarily. <br />
        Please take note of the new link for future use. <br />
        <div>
          <p className='m-4 mb-0'>{redirectPath}</p>
          <button
            className='btn btn-warning btn-sm'
            onClick={() => {
              void navigator.clipboard.writeText(redirectPath);
              toast.success("URL Copied to your clipboard!");
            }}
          >
            Copy Link
          </button>
        </div>
        <br />
      </p>
      <h4>
        Redirecting you in <Countdown className='text-2xl' value={counter} />
        second{counter !== 1 ? "s" : ""}
      </h4>
      <TbHexagons size={150} />
      <button
        className='btn btn-primary'
        onClick={() => {
          window.location.replace(redirectPath);
        }}
      >
        We look forward to serving you in the Hive
      </button>
      <Toaster position='top-right' />
    </div>
  );
}

export default App;
